.footer-icons a {
    display: grid;
    place-items: center;
    width: 100%;
    height: 100%;
}

.link-photos {
    object-fit: contain;
    width: 100%;
    height: 100%;
    max-width: auto;
    max-height: 42px;
    fill: white;
}

.link-photos svg {
    width: auto;
    height: 100%;
}

.footer-icons {
    display: grid;
    grid-auto-flow: column;
    grid-auto-rows: 1fr;
    grid-auto-columns: 1fr;
    
    grid-gap: 10px;
    place-items: center;

}

@media (min-width: 501px)  {
    .footer-icons {
        width: 200px;
        position: absolute;
        bottom: 0;
        right: 0;
    }
}

@media (max-width: 500px)  {
    .footer{
        text-align: center;
    }
    .footer-icons {
        grid-template-columns: repeat(3, 1fr);
        grid-auto-flow: row;
    }
}


.footer-content {
    position: relative;
}