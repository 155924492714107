@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Inter',-apple-system, BlinkMacSystemFont, 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  font-family: 'Inter', sans-serif;
  scroll-behavior: smooth;
}

.banner{
  min-height: calc(90vh);
  width: 100%;
}

#bannerEnd, #aboutEnd, #projectEnd, #eventEnd{
  height: 50px;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.scroll-btn-container div {
  display: flex;
  justify-content: center;
  width: 100%;
}

.scroll-btn-container div a {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.scroll-btn-container div a span {
  display: block;
  height: 20px;
  width: 20px;
  border-bottom: 2px solid #3b82f6;
  border-right: 2px solid #3b82f6;
  transform: rotate(45deg);
  margin: -20px 0;
  animation: animate 2s infinite;
}

.scroll-btn-container div a span:nth-child(2) {
  animation-delay: -0.2s;
}

.scroll-btn-container div a span:nth-child(3) {
  animation-delay: -0.4s;
}

@keyframes animate {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-20px, -20px);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: rotate(45deg) translate(20px, 20px);
  }
}

.skill{
  transition: all 0.15s ease-in-out;
  margin: 10px;
  padding: 10px;
  border-radius: 10px;
  /* background-color: rgb(37, 36, 36); */
  color: rgb(226, 226, 226);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.skill p{
  padding-top: 5px;
  margin: 0px;
}
.skill:hover {
  transform: scale(1.3);
}

@media screen and (max-width: 925px) {
    #notblog {
    display: none;
  }
}

hr.Main-separator{
  max-width: 100%;
  opacity: 95%;
  margin: 20px auto;
  border: 3px solid rgb(194, 194, 192);
  background-color: rgb(194, 194, 192);
  border-radius: 5px;
}

hr.Sub-separator{
  max-width: 80%;
  opacity: 50%;
  margin: 20px auto;
  border: 2px solid rgb(194, 194, 192);
  background-color: rgb(194, 194, 192);
  border-radius: 5px;
}

hr.Section-separator{
  max-width: 1300px;
  width: 70%;
  opacity: 100%;
  margin: 30px auto;
  border: 4px solid rgb(194, 194, 192);
  background-color: rgb(194, 194, 192);
  border-radius: 5px;
}

a{
  color: inherit;
}

a.button{
  color: rgb(237, 237, 237, 1);
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  width: 150px;
  padding: 0 1rem;
  height: 100%;
  border-radius: 3px;
  cursor: pointer;
  appearance: button;
}

a.button:hover {
  color: #000000;
  background: rgba(179, 179, 179, 0.95);
  /* background: red; */
  font-weight: bold;
}

.modalpopup{
  transition: all 0.1s ease-in-out;
  background: none;
  padding: 5px;
  display: inline-block;
  width: 95%;
  /* width: auto; */
  height: auto;
  /* max-height: 100vh; */
  font-family: 'Inter', sans-serif;
  color: rgb(226,226,226);
  font-size: 16px;
  text-align: left;
  border: none;
  margin: auto;
}

.modalpopup:hover{
  /* background: rgba(54, 52, 52, 0.80); */
  transform: scale(1.15);
}
@media screen and (max-width: 800px){
  .modalpopup{
    width: 100%;
  }
}


@media screen and (min-width: 900px){
  .widget-container {
    max-width: 100%;
    display: grid;
    /* grid-template-columns: 2fr 1fr; */
    grid-template-columns: 66% 33%;

    /* grid-gap: 10px; */
    margin: 10px;
    padding: 10px;

  }
}

/* Scroll bar css */
::-webkit-scrollbar {
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #232425;
  
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(97, 97, 102);
  border-radius: 100px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(61, 61, 66);
}

@media screen and (max-width: 500px) {
  .Job-logo{
    float: none;
    display: block;
    margin: 0 auto;
  }
}

@media screen and (max-width: 1100px) {
  .Project-image, .ProjectThumbnail-image, .Job-image, .Event-image, .BlogPost-image{
    width: 100%;
    max-width: 100%;
  }
  .Job-image{
    padding-left: 30px;
  }
}

@media screen and (min-width: 855px){
  .banner-title:after {
    content: '';
    width: 40px;
    height: 7px;
    background: white;
    opacity: 0;
    margin-left: 3px;
    display: inline-block;
    animation: blink .5s infinite alternate;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}